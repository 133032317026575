<template>
  <div
    :class="{ hidden: isOverlayHidden }"
    class="fixed bottom-0 left-0 top-[300px] z-10 w-screen cursor-default bg-black opacity-50 hover:hidden"
  />
  <div
    id="brands-container"
    class="absolute left-0 right-0 z-[50] overflow-x-hidden bg-white p-20 shadow-lg"
  >
    <div class="flex items-center justify-center overflow-x-hidden">
      <ul id="single-brand" class="grid grid-cols-6 gap-16">
        <NuxtLink
          v-for="brand in brands"
          :key="brand.id"
          class="h4 flex cursor-pointer items-center hover:[color:--main]"
          :to="{
            path: `${$features?.home ? `/products` : '/'}`,
            query: { brands: brand.id },
          }"
          @click="
            $parent?.$parent?.$emit('toggle', false), handleBrandClick(brand.id)
          "
        >
          {{ brand.title }}
        </NuxtLink>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {
  Brand,
  FormattedAddress,
} from "~/types/InformativePages/Header/CategoryBundleBrand";
import { useFiltersStore } from "~/store/Features/ProductManagement/filtersStore";

const props = defineProps({
  brands: {
    type: Array as PropType<Brand[]>,
    default: () => [],
  },
  layout: { type: Number, default: 1 },
});

const contactGroups = ref<Record<string, FormattedAddress[]>>({});
const sortedLetters = ref<string[]>([]);
const formattedAddresses = ref<FormattedAddress[]>([]);
const isOverlayHidden = ref(false);
const route = useRoute();
const filterStore = useFiltersStore();

const reFormatAddresses = () => {
  if (props.brands && props.brands.length > 0) {
    formattedAddresses.value = props.brands.map((brand) => ({
      name: brand.title,
      letter: brand.title.charAt(0).toLocaleUpperCase(),
      id: brand.id,
    }));
    sortContacts();
  }
};

const sortContacts = () => {
  if (formattedAddresses.value.length > 0) {
    sortedLetters.value = [
      ...new Set(formattedAddresses.value.map((address) => address.letter)),
    ].sort();

    sortedLetters.value.forEach((letter) => {
      contactGroups.value[letter] = formattedAddresses.value.filter(
        (address) => address.letter === letter,
      );
    });
  }
};

const scrollToGroup = (letter: string) => {
  const groupContainer = document.getElementById(`group-${letter}`);
  if (groupContainer) {
    groupContainer.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  }
};

const handleScroll = () => {
  const scrollY = window.scrollY;
  const threshold = 100;
  isOverlayHidden.value = scrollY > threshold;
};

const handleBrandClick = (brandId: string) => {
  const currentPath = route.path;
  if (currentPath === "/products" || !useNuxtApp().$features?.home) {
    filterStore.clearAllFilters();
    filterStore.applyFilter("brands", brandId);
  }
};

onMounted(() => {
  reFormatAddresses();
  window.addEventListener("scroll", handleScroll);
});

watch(
  () => props.brands,
  (newBrands) => {
    if (newBrands && newBrands.length > 0) {
      reFormatAddresses();
    }
  },
  { immediate: true },
);
</script>
