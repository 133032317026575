<template>
  <div
    class="box-shadow-2 absolute top-8 z-20 hidden w-[28rem] flex-col justify-between bg-white md:flex ltr:right-0 rtl:left-0"
    @mouseleave="$emit('close')"
  >
    <template v-if="!cartStore.isCartEmpty">
      <div class="flex justify-between px-6 py-5">
        <h3 class="display-3 text-lg !font-bold capitalize">
          {{ $t("cart.my_cart") }} ({{ cartStore.cart?.total_quantity ?? 0 }})
        </h3>
        <IconsXIcon class="cursor-pointer md:hidden" @click="emits('close')" />
      </div>

      <div
        class="mx-2 flex max-h-[62vh] flex-col gap-4 overflow-y-auto px-4 md:max-h-80"
      >
        <div v-for="item in items" :key="item.id" class="border-t py-2.5">
          <QuickViewItem :item="item" />
        </div>

        <div
          v-for="(bundle, bundleIndex) in cartStore.cart?.bundles"
          :key="bundleIndex"
          class="relative border-t py-2.5"
        >
          <BundleItem :bundle="bundle" :bundle-index="bundleIndex" />
        </div>
      </div>

      <QuickViewBreakDown :store="cartStore.cart" :quickView="true" />
    </template>

    <EmptyCart class="p-6" v-else />
  </div>
</template>

<script setup lang="ts">
import { Product } from "~/types/ProductManagement/Product";
import { Variant } from "~/types/ProductManagement/variant";
import { useCartStore } from "~/store/Features/Checkout/cartStore";
import QuickViewItem from "./QuickViewItem.vue";
import QuickViewBreakDown from "./QuickBreakDown.vue";
import BundleItem from "./BundleItem.vue";
import EmptyCart from "../CartPage/EmptyCart.vue";

const emits = defineEmits(["close"]);

const cartStore = useCartStore();

const items = computed<(Product | Variant)[]>(() => [
  ...(cartStore.cart?.products ?? []),
  ...(cartStore.cart?.variants ?? []),
]);

function onClickOutside(event: MouseEvent) {
  if ((event.target as HTMLElement).classList.contains("wrapper")) {
    emits("close");
  }
}

onMounted(() => {
  window.addEventListener("click", onClickOutside);
});

onUnmounted(() => {
  window.removeEventListener("click", onClickOutside);
});
</script>
