import { useCategoriesStore } from "~/store/Features/ProductManagement/categoriesStore";
import {
  Brand,
  Bundle,
} from "~/types/InformativePages/Header/CategoryBundleBrand";
import { Category } from "~/types/ProductManagement/Categories";

export default function useHeaderMenuInfo() {
  const brands = useState<Brand[]>(() => []);

  const categories = ref<Category[] | undefined>();
  const bundles = ref<Bundle[]>([]);
  const isLoading = ref(true);

  const { fetchCategories } = useCategoriesStore();
  const nuxtApp = useNuxtApp();
  const getCategories = async () => {
    isLoading.value = true;
    categories.value = await fetchCategories({ include_media: true });
    isLoading.value = false;
  };

  const getBrands = async () => {
    if (nuxtApp.$features?.brands) {
      isLoading.value = true;
      const { res } = await useApi<{ data: Brand[] }>(`/api/v1/brands`);
      if (res?.data) brands.value = res.data;

      isLoading.value = false;
    }
  };

  const getBundles = async () => {
    if (useNuxtApp().$features?.bundles) {
      isLoading.value = true;
      const { res } = await useApi<{ data: Bundle[] }>(`/api/v1/bundles`);
      if (res?.data) bundles.value = res.data;
      isLoading.value = false;
    }
  };

  return {
    categories,
    brands,
    bundles,
    isLoading,
    getBrands,
    getCategories,
    getBundles,
  };
}
