<template>
  <div v-if="!bundle">
    <common-loader class="mx-auto my-16 max-h-16 md:max-h-24" />
  </div>

  <div v-if="bundleItems.length" class="flex flex-col gap-2 pb-2">
    <div v-for="(item, index) in bundleItems" :key="item.id">
      <div class="flex w-full flex-row gap-x-3">
        <NuxtLink
          class="!h-24 !w-24"
          :to="{
            path: `${paths.products.index}/${item?.product_id ?? item?.id}`,
          }"
        >
          <CommonCardImage
            :alt="item.title"
            :firstImage="
              item?.featured_image ?? item?.image ?? item?.product_image
            "
            :secondImage="
              item?.second_image ??
              (item?.media && item?.media.length > 1
                ? item?.media[1]
                : item?.image)
            "
          />
        </NuxtLink>

        <div class="flex min-h-24 w-full flex-col">
          <div class="flex w-full items-start justify-between gap-2">
            <NuxtLink
              class="h2 line-clamp-2"
              :to="{
                path: `${paths.products.index}/${item?.product_id ?? item?.id}`,
              }"
            >
              {{ item?.product_title ?? item?.title }}
            </NuxtLink>
          </div>

          <div class="flex flex-col gap-2">
            <CartItemOptions v-if="item?.product_title" :product="item" />
          </div>
        </div>
      </div>

      <div
        v-if="index != bundleItems.length - 1"
        class="flex w-fit px-10 md:px-7"
      >
        <IconsPlusIcon />
      </div>
    </div>
  </div>

  <IconsXIcon
    class="absolute top-2 cursor-pointer ltr:right-0 rtl:left-0"
    @click="handleRemoveQuantity(bundle)"
  />

  <div class="flex flex-col items-end justify-end">
    <div class="flex flex-col items-center pb-2">
      <div class="bundles__actions__price">
        {{ bundle.original_price }}
      </div>
      <div class="bundles__actions__originalPrice">
        {{ bundle.price }}
      </div>
    </div>
  </div>

  <CartPrompt
    v-if="showPrompt && activeIndex == bundleIndex"
    @closePrompt="showPrompt = false"
    @removeQuantity="handleUpdateQuantity(bundle)"
  />
</template>

<script setup lang="ts">
import type { Bundle } from "~/types/InformativePages/Header/CategoryBundleBrand";
import type { Product } from "~/types/ProductManagement/Product";
import type { Variant } from "~/types/ProductManagement/variant";
import { ProductTypes } from "~/constants/ProductManagement/productTypes";
import { useCartStore } from "~/store/Features/Checkout/cartStore";
import paths from "~/constants/paths";
import CartPrompt from "~/components/features/Checkout/CartPage/CartPrompt.vue";
import CartItemOptions from "../CartPage/CartItemOptions.vue";

const props = defineProps({
  bundle: {
    type: Object as PropType<Bundle>,
    required: true,
  },
  bundleIndex: {
    type: Number,
    required: true,
  },
});

const cartStore = useCartStore();
const showPrompt = ref(false);
const activeIndex = ref(-1);

const bundleItems = computed<(Product | Variant)[]>(() => [
  ...(props.bundle.products ?? []),
  ...(props.bundle.variants ?? []),
]);

const handleUpdateQuantity = async (bundle: Bundle, event: number = 0) => {
  await cartStore?.updateQuantity(ProductTypes.BUNDLE, event, bundle.id);
  showPrompt.value = false;
};
const handleRemoveQuantity = async (bundle: Bundle) => {
  await handleUpdateQuantity(bundle);
};
</script>
