<template>
  <div
    v-if="!isBrandContainerHidden"
    class="fixed bottom-0 left-0 top-[300px] z-10 w-screen cursor-default bg-black opacity-50"
    :class="{ hidden: isOverlayHidden }"
  />
  <div
    v-if="!isBrandContainerHidden"
    id="brand-container"
    class="absolute left-0 right-0 z-[50] flex w-screen items-center justify-center bg-white shadow-lg"
  >
    <div
      id="single-brand"
      style="min-height: 313px"
      class="mt-[1px] grid min-h-[313px] cursor-default grid-cols-3 gap-8 whitespace-nowrap px-[-25%] pb-[54px] pt-[54px] lg:w-4/5 lg:grid-cols-4 lg:gap-8 xl:w-3/5 xl:gap-4"
    >
      <template v-for="brand in brands" :key="brand.id">
        <NuxtLink
          v-if="brand"
          class="display-2 b-3d mx-auto flex h-[95px] w-[180px] cursor-pointer items-center"
          :to="{
            path: '/products',
            query: { brands: brand.id },
          }"
          @click="handleBrandClick(brand.id)"
        >
          <div class="flex items-center space-x-4">
            <div
              v-if="brand?.logo != null && brand?.logo != ''"
              class="flex h-[95px] w-[115px] items-center justify-center bg-[#F5F5F5]"
            >
              <img class="h-2/4 w-3/4" :src="brand?.logo" />
            </div>
            <span class="display-2">{{ brand.title }}</span>
          </div>
        </NuxtLink>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFiltersStore } from "~/store/Features/ProductManagement/filtersStore";
import { Brand } from "~/types/InformativePages/Header/CategoryBundleBrand";

defineProps({ brands: Object as PropType<Brand[]> });

const isOverlayHidden = ref(false);
const isBrandContainerHidden = ref(false);
const route = useRoute();
const filterStore = useFiltersStore();

const handleScroll = () => {
  const scrollY = window.scrollY;
  const threshold = 100;
  isOverlayHidden.value = scrollY > threshold;
};

const handleBrandClick = (brandId: string) => {
  isBrandContainerHidden.value = true;
  const currentPath = route.path;
  if (currentPath === "/products") {
    filterStore.clearAllFilters();
    filterStore.applyFilter("brands", brandId);
  }
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});
</script>
