<template>
  <div
    v-if="availableLocales.length > 1"
    :class="`relative ${currentLocale.dir}`"
    ref="dropdownRef"
  >
    <button class="flex items-center gap-1" @click="toggleMenu">
      <IconsWorldIcon />
      <span class="hidden lg:flex">{{ currentLocale.name }}</span>
      <IconsArrowUpIcon
        class="transition-all"
        :class="showMenu ? 'rotate-0' : '-rotate-180'"
      />
    </button>

    <!-- Dropdown menu -->
    <div
      v-if="showMenu"
      class="absolute top-full z-20 mt-2 flex w-32 flex-col rounded-md border bg-white shadow-lg"
    >
      <button
        v-for="(lang, index) in availableLocales"
        class="flex w-full items-center justify-between px-5 py-2 text-center hover:bg-gray-100 focus:outline-none"
        :class="{ 'border-t': index !== 0 }"
        :key="lang.code"
        @click="selectLanguage(lang)"
      >
        <span>
          {{ lang.name }}
        </span>
        <IconsCheckMark
          v-if="currentLocale.code === lang.code"
          fill-color="black"
          class="!h-6 !w-6"
        />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";
import useLocale, { LangType } from "~/composables/Common/useLocale";

const dropdownRef = ref();
const { currentLocale, availableLocales, setLang } = useLocale();

const showMenu = ref(false);

const toggleMenu = () => {
  showMenu.value = !showMenu.value;
};

const closeMenu = () => {
  showMenu.value = false;
};

const selectLanguage = (lang: LangType) => {
  setLang(lang, true);
  closeMenu();
};

onClickOutside(dropdownRef, closeMenu);
</script>
