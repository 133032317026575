<template>
  <div v-if="$features?.brands">
    <BrandsMobileModalButton v-if="!showModal" />
    <div
      v-if="showModal"
      :class="{
        'px-[1.7rem]': $features?.brands?.screens?.brands_screen != '1',
      }"
    >
      <BrandContainer :brands="brands" @click="$emit('close')" />
    </div>
  </div>
</template>

<script setup lang="ts">
import BrandContainer from "~/components/features/Header/MenuNav/BrandContainer.vue";
import BrandsMobileModalButton from "~/components/features/Header/Layouts/Brands/MobileModalButton.vue";
import useHeaderMenuInfo from "~/composables/features/Header/useHeaderMenuInfo";

defineProps(["showModal"]);
const emits = defineEmits(["viewBrands", "close"]);

const { brands } = useHeaderMenuInfo();
</script>
