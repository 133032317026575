<template>
  <div id="modal" class="fixed left-0 top-0 z-50 h-full w-full bg-white">
    <!-- Modal Header -->
    <div class="flex items-center justify-between px-8 py-6">
      <div
        class="w-6"
        :class="[showBrandsModal || viewingSubcategories ? 'block' : 'hidden']"
      >
        <IconsArrowUpIcon
          class="w-full rotate-[270deg] transform cursor-pointer"
          @click="closeModal"
        />
      </div>
      <MobileModalHeader />
      <IconsXIcon @click="emits('close')" class="cursor-pointer" />
    </div>

    <!-- Auth -->
    <MobileModalAuth @click="$emit('close')" />
    <!-- Categories Layout == 1 to be removed while implementing 3 level categories for other layouts -->
    <div v-if="!viewingSubcategories && !showBrandsModal">
      <CategoriesContainerMobileView
        :categoriesLayout="categoriesLayout"
        :categories="categories"
        @category-selected="handleCategorySelected"
        @close="emits('close')"
      />
    </div>
    <div v-if="viewingSubcategories && selectedCategory?.children">
      <SubCategoriesContainer
        :subcategories="selectedCategory?.children"
        :categoriesLayout="categoriesLayout"
        :selectedCategory="selectedCategory"
        @sub-category-selected="handleSubCategorySelected"
        @collection-selected="handleCollectionSelected"
        @view-all-selected="handleViewAllSelected"
        @close="emits('close')"
      />
    </div>

    <template v-if="categoriesLayout === '1' && !viewingSubcategories">
      <div class="px-[1.7rem]">
        <div
          v-if="!showBrandsModal && $features?.bundles && bundles"
          @click="$router.push('/bundles'), $emit('close')"
          class="flex cursor-pointer items-center justify-between border-t border-[#C1C1C1] py-5 font-bold"
        >
          <div class="h2 block cursor-pointer">
            {{ $t("common.titles.Bundles") }}
          </div>
          <IconsArrowUpIcon class="rotate-[-270deg] transform" />
        </div>
      </div>
    </template>
    <template v-if="categoriesLayout === '3' && !viewingSubcategories">
      <div
        v-if="!showBrandsModal && $features?.bundles && bundles"
        class="flex cursor-pointer items-center gap-8 border-t border-grey-lightest px-[1.7rem] py-5 font-bold"
        @click="$router.push('/bundles'), $emit('close')"
      >
        <div
          class="image flex h-[80px] w-[80px] items-center justify-center bg-[#F5F5F5]"
        >
          <span class="h2 capitalize text-grey-lightest"> bundles </span>
        </div>
        <div class="h2 subcategory-title block cursor-pointer capitalize">
          Bundles
        </div>
      </div>
    </template>

    <template v-if="categoriesLayout === '2' && !viewingSubcategories">
      <div class="l3-mobile-cat-grid mt-4">
        <div class="flex items-center justify-center">
          <div class="gap-l-[18px] gap-x-50 grid gap-y-3 px-0">
            <div
              v-if="!showBrandsModal && $features?.bundles && bundles"
              @click="$router.push('/bundles'), $emit('close')"
              class="flex cursor-pointer flex-col items-center justify-center border-[#C1C1C1] px-[1.7rem] font-bold"
            >
              <div
                class="flex h-[120px] w-[156px] items-center justify-center bg-[#F5F5F5]"
              >
                <span class="display-2 block cursor-pointer text-[#C1C1C1]">
                  {{ $t("common.titles.Bundles") }}
                </span>
              </div>
              <span class="display-2 mt-4 block cursor-pointer">
                {{ $t("common.titles.Bundles") }}
              </span>
            </div>
          </div>
          <MobileModalBrands
            v-if="!viewingSubcategories"
            :showModal="showBrandsModal"
            @click="
              showBrandsModal == false
                ? (showBrandsModal = true)
                : $emit('close')
            "
          />
        </div>
      </div>
    </template>

    <!-- Brands -->

    <MobileModalBrands
      v-if="categoriesLayout !== '2' && !viewingSubcategories"
      :showModal="showBrandsModal"
      @click="
        showBrandsModal == false ? (showBrandsModal = true) : $emit('close')
      "
      class="px-[1.7rem]"
    />

    <!-- Language -->
    <!-- <Lang /> -->
  </div>
</template>

<script setup lang="ts">
import MobileModalAuth from "~/components/features/Header/MenuNav/MobileModalAuth.vue";
import MobileModalHeader from "~/components/features/Header/MenuNav/MobileModalHeader.vue";
import MobileModalBrands from "~/components/features/Header/MenuNav/MobileModalBrands.vue";
import CategoriesContainerMobileView from "./CategoriesContainerMobileView.vue";
import SubCategoriesContainer from "./SubCategoriesContainer.vue";
import { Category } from "~/types/ProductManagement/Categories";
import { useFiltersStore } from "~/store/Features/ProductManagement/filtersStore";

defineProps(["categories", "categoriesLayout", "showCategoryModal", "bundles"]);
const emits = defineEmits(["close"]);

const showBrandsModal = ref(false);
const features = ref(useNuxtApp().$features);
const categoriesLayout = features.value?.categories?.screens
  ?.categories_screen as string;

const closeModal = () => {
  showBrandsModal.value = false;
  viewingSubcategories.value = false;
};

const viewingSubcategories = ref(false);
const selectedCategory = ref<Category>();
const filtersStore = useFiltersStore();

const handleCategorySelected = (category: any) => {
  if (category.children && category.children.length > 0) {
    selectedCategory.value = category;
    viewingSubcategories.value = true;
  } else {
    filtersStore.clearAllFilters();
    filtersStore.applyFilter("categories", category.id);
    emits("close");
  }
};
const handleSubCategorySelected = (subcategory: any) => {
  if (!subcategory.children || subcategory.children.length === 0) {
    filtersStore.clearAllFilters();
    filtersStore.applyFilter("sub_categories", subcategory.id);
    emits("close");
  }
};
const handleCollectionSelected = (collection: any) => {
  if (!collection.children || collection.children.length === 0) {
    filtersStore.clearAllFilters();
    filtersStore.applyFilter("collections", collection.id);
    emits("close");
  }
};
const handleViewAllSelected = (selectedCategory: any) => {
  filtersStore.clearAllFilters();
  filtersStore.applyFilter("categories", selectedCategory.id);
};

onMounted(() => {
  const modalElement = document.getElementById("modal");
  if (modalElement) {
    modalElement.style.overflow = "auto";
  }
});
</script>
