<template>
  <div>
    <img
      v-if="!selectedCategory && !showBrandsView"
      class="max-h-28 max-w-28"
      :src="$appConfig?.web_icon_url"
      alt="Logo"
    />
    <div v-else-if="selectedCategory && !showBrandsView" class="">
      <span class="title-1 hidden md:block">
        {{ selectedCategory.title }}
      </span>
      <span class="title-1 h2 block md:hidden">
        {{ selectedCategory.title }}
      </span>
    </div>

    <div v-else-if="showBrandsView" class="title-1 h2">
      {{ $t("informative.header.titles.shop_by_brands") }}
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Category } from "~/types/ProductManagement/Categories";

// FIXME: props and events are not typed
defineProps(["categories", "showBrands", "categoriesLayout"]);
const emits = defineEmits(["viewBrands", "close"]);

// FIXME: unused states
const selectedCategory = ref(null as Category | null);
const showBrandsView = ref(false);
</script>
