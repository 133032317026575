<template>
  <div v-if="suggestions" class="section">
    <div class="title">
      {{ $t("informative.header.titles.suggestion") }}
    </div>
    <NuxtLink
      @click="clickSuggest(String(suggest)), handleClick(String(suggest))"
      v-for="suggest in suggestions"
      :key="suggest"
      class="suggest"
    >
      {{ suggest }}
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import { useFiltersStore } from "~/store/Features/ProductManagement/filtersStore";
const filterStore = useFiltersStore();
defineProps<{ suggestions: string[] }>();
const emit = defineEmits(["add-to-recents"]);
const router = useRouter();
const nuxtApp = useNuxtApp();
const clickSuggest = (value: string) => {
  filterStore.clearAllFilters();

  let searchValue = "";
  if (value.endsWith('"')) {
    searchValue = value.replace('"', "");
  } else {
    searchValue = value.substring(value.indexOf('"') + 1);
  }
  searchValue = String(searchValue);
  router.push({
    path: "/products",
    query: { search: searchValue },
  });
};
const handleClick = (title: string) => {
  emit("add-to-recents", title);
};
</script>
