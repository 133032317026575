<template>
  <div
    class="gap-l-[18px] gap-x-50 grid grid-cols-2 items-center justify-center gap-y-3 px-0"
  >
    <NuxtLink
      v-for="(brand, i) in brands"
      :key="brand.id"
      :class="{ 'border-none': i === 0 }"
      class="display-2 flex h-[120px] w-[156px] cursor-pointer flex-col items-center justify-center rounded-[3px] bg-[#F5F5F5] font-bold"
      @click="$parent?.$parent?.$emit('close'), handleBrandClick(brand.id)"
      :to="{
        path: '/products',
        query: { brands: brand.id },
      }"
    >
      <CommonUImg
        class="h-1/4 w-2/4"
        :src="brand.logo || ''"
        :alt="brand.title"
      />
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import { Brand } from "~/types/InformativePages/Header/CategoryBundleBrand";
import { useFiltersStore } from "~/store/Features/ProductManagement/filtersStore";

defineProps({
  brands: Array as PropType<Brand[]>,
  layout: { type: Number, default: 1 },
});

const route = useRoute();
const filterStore = useFiltersStore();

const handleBrandClick = (brandId: string) => {
  const currentPath = route.path;
  if (currentPath === "/products") {
    filterStore.clearAllFilters();
    filterStore.applyFilter("brands", brandId);
  }
};
</script>
