<template>
  <div>
    <NuxtLink
      v-for="(brand, i) in brands"
      class="h2 flex cursor-pointer items-center gap-4 border-t border-[#C1C1C1] py-5 font-bold"
      :key="brand.id"
      :class="{ 'border-none': i == 0 }"
      :to="{
        path: '/products',
        query: { brands: brand.id },
      }"
      @click="$parent?.$parent?.$emit('close'), handleBrandClick(brand.id)"
    >
      <CommonUImg
        class="h-2/4 w-1/4"
        :src="brand.logo || ''"
        :alt="brand.title"
      />

      <span
        style="
          font-family: Lato;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0px;
          text-align: center;
          text-transform: capitalize;
        "
        class="subcategory-title h2 block cursor-pointer"
      >
        {{ brand.title }}
      </span>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import { useFiltersStore } from "~/store/Features/ProductManagement/filtersStore";
import { Brand } from "~/types/InformativePages/Header/CategoryBundleBrand";

defineProps({
  brands: Object as PropType<Brand[]>,
  layout: { type: Number, default: 1 },
});

const route = useRoute();
const filterStore = useFiltersStore();

const handleBrandClick = (brandId: string) => {
  const currentPath = route.path;
  if (currentPath === "/products") {
    filterStore.clearAllFilters();
    filterStore.applyFilter("brands", brandId);
  }
};
</script>
